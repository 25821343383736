import React, { useState, useEffect, useRef } from "react";
import Card from "./Card.js";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { NavLink, Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Token_List_Func } from "../actions/axioss/nft.axios";
import Button from "react-bootstrap/esm/Button.js";
import NoData from "./seperatemodals/nodata.jsx";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Col, Row } from "react-bootstrap";

import Lottie from "lottie-react";
import Doodles from '../assets/images/vertendi/lotties/doodles.json'
import PlainPausePlay from '../assets/images/vertendi/lotties/plainPasePlay.json'
import PlainPlayer from '../assets/images/vertendi/lotties/plainPlayer.json'

export default function Explore() {
  const [loadingstate, setLoadingstate] = useState(true);
  const [category, setCategory] = React.useState(1);
  const [show, setShow] = React.useState(false);
  const [filter, setFilter] = useState("recentcreated");
  var [filtershow, setFiltershow] = useState("Recently Created");
  const { Categorys } = useSelector((state) => state.LoginReducer);
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const { Category } = useParams();
  const [categorydescription , Setcategorydescription] = useState()

  console.log("category", payload, Category);

  const [TabName, SetTabName] = useState("All");
  const [pageno, SetPageno] = useState(0);
  var LikeForwardRef = useRef();
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [Tokens, SetTokens] = useState({
    All: { loader: true, page: 1, list: [] },
  });
  const Tabname = (newValue) => {
    SetTabName(newValue);
  };

  const chatContainerRef = useRef(null);

  
  useEffect(() => {
    // Scroll to the bottom of the chat container when messages change
    if (chatContainerRef.current) {
      let val =  chatContainerRef.current.scrollHeight / pageno
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight - val - (val/2);
      console.log('chatContainerRef.current.scrollHeight' , chatContainerRef.current.scrollHeight)
    }
  }, [pageno]);



  useEffect(() => {
    if (Category) {
      if (Category !== TabName) {
        SetTabName(Category);
        Explore(1, Category);
      }
    } else {
      Explore();
    }
  }, [Category]); 

  useEffect(() => {
    // if (typeof Tokens[TabName] == "undefined") {
    if (
      typeof Tokens[TabName] == "undefined" ||
      Tokens[TabName].filter !== filter
    ) {
      Tokens[TabName] = { page: 1, list: [], loader: false };
      SetTokens(Tokens);
      Explore(1, TabName);
    }
  }, [TabName, filter]);

  const Explore = async (data, tab) => {
    setLoadingstate(true);

    var page = data ? data : Tokens[TabName]?.page;
    var SendDATA = {
      TabName: tab ? tab : TabName,
      limit: 12,
      ProfileUrl: payload?.ProfileUrl ? payload.ProfileUrl : "",
      page: page ?? 1,
      from: "Explore",
      filter: filter,
    };
    let Resp = await Token_List_Func(SendDATA);
    console.log("Check datad", Resp);
    if (Resp?.data) {
      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: [...Tokens[TabName].list, ...Resp.data],

            loader:
              Resp.data.length === 0 ||
              (SendDATA.TabName === "usercollection" && Resp.cursor == null)
                ? false
                : true,
            page: Tokens[TabName].page,
            filter: filter,
          },
        },
      });
      setLoadingstate(false);
    } else
      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: Tokens[TabName].list,
            loader: false,
            page: Tokens[TabName].page,
            filter: filter,
          },
        },
      });
    setLoadingstate(false);
  };


  const LoadMore = async() => {
    // if (chatContainerRef.current) {
    //   chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    // }
    Tokens[TabName].page = Tokens[TabName].page + 1;
    SetTokens(Tokens);
    await Explore(Tokens[TabName].page);
    SetPageno(Tokens[TabName].page)

  };
  function LikeList(data) {
    setLikedTokenList(data);
  }

  // useEffect(() => {
  //   if (Category) {
  //     console.log("VALUE",Category);
  //     SetTabName(Category)
  //     Explore(1, Category)
  //   }
  //   else {
  //     Explore();
  //   }
  // }, [Category]);

  console.log("tokeokokeokoekoeke", Tokens);



  return (
    <>
      <Header />
      <div className="explore vert__explorer">
        <img src={require('../assets/images/vertendi/leftBlurBall.png')} className="vert__exploreBlurBall" />
        <img src={require('../assets/images/vertendi/rightBlurBall.png')} className="vert__exploreRightBlurBall" />
        <div className="rightDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="leftDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="explorePauseplayHolder">
            <Lottie animationData={PlainPausePlay} loop />  
          </div>
          <div className="explorePlayerHolder">
              <Lottie animationData={PlainPlayer} loop />
          </div>
        {/* <section className="tf-page-title" data-aos="fade-right">
          <div className="tf-container custom__vertContainer container">
            <div className="row">
              <div className="col-md-12">
                <ul className="breadcrumbs firaFont">
                  <li>
                    <NavLink to="/">
                      Home <MdKeyboardArrowRight />
                    </NavLink>
                  </li>
                  <li>Explore</li>
                </ul>
                <h4 className="page-title-heading crossfitFont">Explore</h4>
              </div>
            </div>
          </div>
        </section> */}
        <section
          className="tf-live-auction explore tf-filter"
          data-aos="fade-left"
        >
          <div className="tf-container custom__vertContainer container">
            <div className="row ">
              <div className="col-md-12">
                <div className="top-menu wow fadeInUp vert_topMenu" data-aos="fade-up">
                 
                    <ul className="filter-menu">
                    <Link to="/explore" className="helveticaFont">
                      <li
                        className={TabName == "All" ? "active" : ""}
                        onClick={() => {
                          Setcategorydescription("")
                          Tabname("All")}}
                      >
                        <span>All</span>
                      </li>
                    </Link>
                    {Categorys.map((item) => {
                      return (
                        <Link to="/explore" className="helveticaFont">
                          <li
                            className={TabName == item.label ? "active" : ""}
                            onClick={() =>{
                              Setcategorydescription(item.description)
                              Tabname(item.label)}}
                          >
                            <a>{item.label}</a>
                          </li>
                        </Link>
                      );
                    })}
                    {/* <li className={category == 1 ? "active" : ""} onClick={() => setCategory(1)}><a>3D MODEL</a></li>
                                    <li className={category == 2 ? "active" : ""} onClick={() => setCategory(2)}><a>ANIME/MANGA</a></li>
                                    <li className={category == 3 ? "active" : ""} onClick={() => setCategory(3)}><a>CYBER PUNK</a></li>
                                    <li className={category == 4 ? "active" : ""} onClick={() => setCategory(4)}><a>PIXEL ART</a></li>
                                    <li className={category == 5 ? "active" : ""} onClick={() => setCategory(5)}><a>MUSIC</a></li>
                                    <li className={category == 6 ? "active" : ""} onClick={() => setCategory(6)}><a>ABSTRACT</a></li>
                                    <li className={category == 7 ? "active" : ""} onClick={() => setCategory(7)}><a>2D ARTS</a></li> */}
                  </ul>
                    <div id="item_category2" className="dropdown">
                    <Link to="#" className="btn-selector nolink allshow">
                      {filtershow}
                    </Link>
                    <ul className="show">
                      <li
                        onClick={() => {
                          setFilter("recentlisted");
                          setFiltershow("Recently Listed");
                        }}
                      >
                        <span>Recently Listed</span>
                      </li>
                      <li
                        className="active"
                        onClick={() => {
                          setFilter("recentcreated");
                          setFiltershow("Recently Created");
                        }}
                      >
                        <span>Recently Created</span>
                      </li>
                      <li
                        onClick={() => {
                          setFilter("recentsold");
                          setFiltershow("Recently Sold");
                        }}
                      >
                        <span>Recently Sold</span>
                      </li>
                      <li
                        onClick={() => {
                          setFilter("PriceHighToLow");
                          setFiltershow("High to Low");
                        }}
                      >
                        <span>High to Low</span>
                      </li>
                      <li
                        onClick={() => {
                          setFilter("PriceLowToHigh");
                          setFiltershow("Low to High");
                        }}
                      >
                        <span>Low to High</span>
                      </li>
                      <li
                        onClick={() => {
                          setFilter("oldest");
                          setFiltershow("Oldest");
                        }}
                      >
                        <span>Oldest</span>
                      </li>
                    </ul>
                  </div>
                  
                </div>
              </div>
            </div>
           {categorydescription&&  <div>
              
              <div className="firaFont  text-white mb-5" dangerouslySetInnerHTML={{__html: categorydescription}}/>
              {/* {categorydescription} */}
            </div>}
            <div className="tf-filter-container row hgt_set"  ref={chatContainerRef} data-aos="fade-up">
            
            
              {/* {category == 1 ? */}
              {/* {console.log('likrreeeeexple',LikeForwardRef,setLikedTokenList,LikedTokenList)} */}
              
              {
              // loadingstate == true ? (
              //   <>
              //     <div className="text-centre">
              //       <h3>Loading...</h3>
              //       {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
              //       {/* <p>Nothing for ReSale at this time</p> */}
              //       <div className="load-more">
              //         {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
              //       </div>
              //     </div>
              //   </>
              // ) :
              
              TabName && Tokens[TabName]?.list.length > 0 ? (
                Tokens[TabName]?.list.map((item, index) => (
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber">
                    {console.log("datainexplore", item)}

                    <Card
                      product={item}
                      index={index}
                      LikeList={LikeList}
                      LikedTokenList={LikedTokenList}
                      setLikedTokenList={setLikedTokenList}
                      LikeForwardRef={LikeForwardRef}
                    />
                  </div>
                ))
              ) : (
                <NoData />
              )}

{Tokens[TabName]?.loader && (
              <div className="col-md-12">
                <div className="btn-loadmore mt17">
                  <Button className="tf-button loadmore vert__cmnGoldBtn firaFont" onClick={LoadMore}>
                  {  loadingstate === true ? "Loading..." : "Load More"}
                  </Button>
                </div>
              </div>
            )}
            </div>

          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}
