import { Box, Button, Fade, Grid,  } from "@mui/material";
import Modal from 'react-bootstrap/Modal';
import { useState,useEffect } from "react";

import config from '../config/config'
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton, FacebookMessengerShareButton } from 'react-share';
import { getnfttaglist } from "../../actions/axioss/nft.axios";
import MessengerShareButton from "./messager";
import { toast } from "react-toastify";

export function Share({onhide , title,url,from,closePop,quote}){
    const [tag,setTag]=useState({})
    useEffect(()=>{
        getnfttaglists()
    },[])

    function CopyClipboad() {
        // Get the text field
        navigator?.clipboard?.writeText(url);
        setTimeout(()=>{
         toast.success("url Copied ");
        },1000)


      }


    const getnfttaglists=async()=>{
        var resp=await getnfttaglist();
        console.log("nft tage list",resp.data)
        var hastag=resp?.data?.filter(item=> item?.details == "Hashtag")
        console.log(hastag,"hash");
        setTag(hastag[0])
    }
    console.log("urlklllklkurl",url,title);
    return(
    <Modal
    // aria-labelledby="transition-modal_title"
    // aria-describedby="transition-modal-description"
    // open={true}
    // className={"customized_modal modal fade show"}
    size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true}
  >
    <Modal.Header>
    
      {/* <button type="button" class="btn-close" aria-label="Close" onClick={closePop}></button> */}
      <Modal.Title id="contained-modal-title-vcenter" className="share_modal_ttl">
        Share
        <button className="modal_close_btn" aria-label="Close" onClick={()=> {closePop(); onhide();} }>&times;</button>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className='placeabid'>
        {console.log("taggggggg",`${url}${tag.nfttag}`)}

                            <div className="modal_body flex">
                                <Grid container>
                                    <Grid item sm={3} xs={6}>
                                        <div className="text-center icon_div">
    
                                            <TwitterShareButton
                                                title={title}
                                                url={`${url}${tag.nfttag}`}
                                            >
                                                <i className="fab fa-twitter"></i>
                                                <p>Twitter</p>
    
    
                                            </TwitterShareButton>
    
                                        </div>
                                    </Grid>
                                    
                                    <Grid item sm={3} xs={6}>
                                        <div className="text-center icon_div">
                                            <FacebookShareButton
                                                quote={quote}
                                                // title={`${productdetail.TokenId}  NFT`}
                                                url={`${url}${tag.nfttag}`}
                                            
                                            //     via={`${config.FRONT_URL}`}
                                            >
                                                <i className="fab fa-facebook-f"></i>
                                                <p>Facebook</p>
                                            </FacebookShareButton>
    
                                        </div>
                                    </Grid>
                                    
                                    {/* <Grid item sm={3} xs={6}>
                                        <div className="text-center icon_div">
                                            {console.log('kasxxx' , url  )}

                                                     <MessengerShareButton relurl={url}/>
                                        </div>
                                    </Grid> */}

                                    <Grid item sm={3} xs={6}>
                                        <div className="text-center icon_div">
    
                                            <TelegramShareButton
                                                title={title}
                                                url={`${url}${tag.nfttag}`}
                                            
                                            >
    
                                                <i className="fab fa-telegram-plane"></i>
                                                <p>Telegram</p>
    
                                            </TelegramShareButton>
    
                                        </div>
                                    </Grid>
                                              
                                    <Grid item sm={3} xs={6}>
                                        <div className="text-center icon_div " onClick={CopyClipboad}>
                                            <div className="clipboard-share">
                                    <i class="fa-solid fa-clipboard"></i>
                                    <p>copy clipboad</p>
                                    </div>
                                    </div>
                                    </Grid>


                                    <Grid item sm={3} xs={6}>
                                        <div className="text-center icon_div">
                                            <WhatsappShareButton
                                               title={title}
                                               url={`${url}${tag.nfttag}`}
                                             >
                                                <i className="fab fa-whatsapp"></i>
                                                <p>Whatsapp</p>
                                            </WhatsappShareButton>
    
                                        </div>
                                    </Grid>
                                    
                                    <Grid item sm={3} xs={6}>
                                        <div className="text-center icon_div">
                                            <FacebookMessengerShareButton
                                                appId="526545833664250"
                                               title={title}
                                               url={`${url}${tag.nfttag}`}
                                             >
                                                <i className="fab fa-whatsapp"></i>
                                                <p>Messenger</p>
                                            </FacebookMessengerShareButton>
    
                                        </div>
                                    </Grid>
                                    
                                    {/* <Grid item sm={4} xs={6}>
                                        <div className="text-center icon_div">
    
                                           <WhatsappShareButton
                                               title={title}
                                               url={url}
                                             > 
                                                <i className="fab fa-instagram"></i>
                                                <p>Instagram</p>
                                             </WhatsappShareButton>
                                        </div>
                                    </Grid>  */}
                                   {/* <Grid item sm={4} xs={6}>
                                        <div className="text-center icon_div" >
                                            <button
                                               title={title}
    
                                               url={url}
                                             > 
                                                <i className="fab fa-tiktok"></i>
                                                <p>TikTok</p>
                                            {/* </WhatsappShareButton> 
    
                                        </div>
                                    </Grid>  */}
                                </Grid>
                                </div>
    </div>
    </Modal.Body>
            </Modal>
    )

}